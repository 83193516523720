::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #b5b5b5;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  background-clip: content-box;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #8fc9de;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary);
}
::-webkit-scrollbar-thumb:active {
  background-color: var(--primary);
}
::-webkit-scrollbar:horizontal {
  width: 10px;
  height: 8px;
}
::-webkit-scrollbar-track:horizontal {
  height: 8px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

//// for-firefox
.scroller {
  scrollbar-color: var(--primary) #8fc9de; /* thumb and track color */
  scrollbar-width: thin;
}
