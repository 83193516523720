// .form-control {
//     padding: 0 0.5rem;
//     line-height: $input-height;
// }

// .input-group-append,
// .input-group-prepend {
//     .mat-button {
//         padding: 0;
//         min-width: auto;
//         width: 100%;
//         line-height: $input-height;
//     }
// }

.form-group {
  margin-bottom: 0.5rem;
  position: relative;
  width: 100%;

  label {
    // opacity: 0.8;
    font-size: 13px;
    margin-bottom: 0;
    color: #818588 !important;
    font-weight: 100;
  }

  .input-group {
    position: relative;

    .input-group-append,
    .input-group-prepend {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      width: 30px;
      height: 36px;
      line-height: 36px;

      .i-btn {
        min-width: 30px !important;
        padding: 0 !important;
      }
    }

    .input-group-prepend + .form-control {
      padding-left: 36px;
    }

    .form-control {
      padding-right: 36px !important;
    }

    .input-group-prepend {
      left: 0;

      .i-btn {
        height: 30px;
        display: block;
        text-align: center;

        ion-icon {
          width: 16px;
          height: 30px;
        }
      }
    }
  }

  .form-control {
    width: 100%;
    border-radius: 3px;
    // border: 1px solid #d1cfcf;
    border: 1px solid rgb(178, 178, 178);
    padding: 5px 6px;
    height: 30px;
    line-height: 1.25;
    font-size: 14px;

    &:focus {
      outline: 0;
      border-color: var(--secondary);
    }
  }

  textarea.form-control {
    height: auto;
  }
}

.form-control::placeholder {
  color: #777777;
  opacity: 1;
  font-size: 13px;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #777777;
  opacity: 1;
  font-size: 13px;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #777777;
  opacity: 1;
  font-size: 13px;
}

.form-margin-0 {
  .form-group {
    margin-bottom: 0 !important;
  }
}

.chips-control {
  width: 100%;
  height: auto;
  max-height: 100px;
  // border: 1px solid #d1cfcf;
  border: 1px solid rgb(178, 178, 178);
  border-radius: 3px;

  &:focus {
    outline: 0;
  }

  padding: 5px 6px;
  // margin-bottom: 0.5rem;
  position: relative;
  // display:flex;
  // align-items: center;
  overflow: auto;
}

.mat-chip {
  font-size: 12px !important;
  // margin: 0 3px 0 0 !important;
  min-height: 24px !important;
}

input.mat-chip-input {
  border: none;

  &:focus,
  &:active,
  &:hover {
    outline: 0;
  }
}
.form-control:disabled {
  background-color: #00000012;
}

.lh15 {
  line-height: 15px;
}
.form-control::placeholder {
  font-size: 12px !important;
  color: #d0d1d2fa !important;
  font-weight: 100;
}
.border-warn {
  border: 1px solid var(--danger) !important;
}
.h20 {
  height: 20px !important;
}
