@each $color,
$value in $theme-colors {
    .border-#{$color} {
        border-color: var(--#{$color}) !important;
    }
    .text-#{$color} {
        color: var(--#{$color}) !important;
        &:hover, &:focus{
            color: darken($value, 15%) !important;
        }
    }
}