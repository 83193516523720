.card {
  // padding: 1rem;
  // box-shadow: 0px 6px 30px rgb(0 0 0 / 12%);
  // box-shadow: 0px 6px 15px 0px rgb(0 0 0 / 12%);
  // box-shadow: 0px 0px 1px 1px rgb(224 224 224);
  border: 1px solid #e0e0e0;
  background-color: white;
  // border-top: 3px solid var(--secondary);
  border-radius: 5px 5px 0 0;

  .card-header {
    display: flex;
    align-items: center;
    z-index: 2;
    position: relative;
    height: 36px;
    padding: 0 0 0 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);

    .card-title {
      font-size: 1rem;
      letter-spacing: 1px;
      margin-bottom: 0;
    }

    .mat-icon-button {
      line-height: 34px;
      height: 34px;

      ion-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.top-card {
  box-shadow: 0px 6px 15px 0px rgb(0 0 0 / 12%);
}
.box-label-card {
  text-align: center;
  padding: 1rem;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-radius: 5px;
  height: 100%;
}
