.mat-button,
.mat-raised-button {
    line-height: 30px !important;
    font-weight: 400 !important;
    font-size: 13px;
    border-radius: 4px !important;
    padding: 0 1rem !important;
    text-align: center;
    text-transform: capitalize;
    ion-icon{
        width: 15px;
        height: 15px;
        padding-right: 5px;
    }
    &.btn-green{
        background-color: #006009 !important; 
        color: #ffffff;
    }
    &.btn-red{
        background-color: #d00404 !important;
        color: #ffffff;
    }
    &.btn-trans{
        background-color: #0000000f;
        color: #003a62;
    }
    &a{
        text-decoration: none !important;
    }
}

.icon-btn {
    padding-left: 46px !important;
    background-color: var(--secondary);
    overflow: hidden !important;
    color: white;

    .icon-box {
        width: 30px;
        height: 30px;
        line-height: 30px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: var(--primary);
        font-size: 18px;

        ion-icon {
            height: 28px;
        }
    }
}
.svg-only-btn{
    ion-icon{
    height: 14px !important;
    width: 14px !important;
    line-height: 14px !important;
    font-size: 14px;
    }
}

.icon-only-btn{
    ion-icon{
    height: 20px !important;
    width: 20px !important;
    line-height: 20px !important;
    font-size: 20px;
    }
}
.upload-btn{
    cursor: pointer;
    color: #009688;
    ion-icon{
        font-size: 120px;
    }
}


::ng-deep .mat-button-focus-overlay {
    opacity: 0 !important;
    background:none !important;
    }