// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$theme-typography: mat-typography-config(
  $font-family: "'Poppins', sans-serif",
);

@include mat-core($theme-typography);

$theme-primary: (
  50: #e0e7ec,
  100: #b3c4d0,
  200: #809db1,
  300: #4d7591,
  400: #26587a,
  500: #003a62,
  600: #00345a,
  700: #002c50,
  800: #002546,
  900: #001834,
  A100: #6ca0ff,
  A200: #397fff,
  A400: #065dff,
  A700: #0053eb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$theme-accent: (
  50: #e0f2fb,
  100: #b3dff5,
  200: #80c9ee,
  300: #4db3e7,
  400: #26a3e2,
  500: #0093dd,
  600: #008bd9,
  700: #0080d4,
  800: #0076cf,
  900: #0064c7,
  A100: #eff6ff,
  A200: #bcd9ff,
  A400: #89bdff,
  A700: #6fafff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$theme-warn: (
  50: #fef4ec,
  100: #fce3d0,
  200: #fad1b0,
  300: #f7be90,
  400: #f6b079,
  500: #f4a261,
  600: #f39a59,
  700: #f1904f,
  800: #ef8645,
  900: #ec7533,
  A100: #ffffff,
  A200: #fffefe,
  A400: #ffddcb,
  A700: #ffccb2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$theme-success: (
  50: #e4f9e8,
  100: #baf0c5,
  200: #8de69e,
  300: #5fdb77,
  400: #3cd45a,
  500: #1acc3d,
  600: #17c737,
  700: #13c02f,
  800: #0fb927,
  900: #08ad1a,
  A100: #d9ffdc,
  A200: #a6ffac,
  A400: #73ff7d,
  A700: #59ff66,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$theme-danger: (
  50: #fceeea,
  100: #f8d4cb,
  200: #f3b7a8,
  300: #ee9a85,
  400: #eb856b,
  500: #e76f51,
  600: #e4674a,
  700: #e05c40,
  800: #dd5237,
  900: #d74027,
  A100: #ffffff,
  A200: #ffe4e0,
  A400: #ffb8ad,
  A700: #ffa194,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-ui-primary: mat-palette($theme-primary);
$theme-ui-accent: mat-palette($theme-accent);

// The warn palette is optional (defaults to red).
$theme-ui-warn: mat-palette($theme-warn);
$theme-ui-danger: mat-palette($theme-danger);
$theme-ui-success: mat-palette($theme-success);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme-ui-theme: mat-light-theme(
  (
    color: (
      primary: $theme-ui-primary,
      accent: $theme-ui-accent,
      warn: $theme-ui-warn,
      danger: $theme-ui-danger,
      success: $theme-ui-success,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($theme-ui-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-danger {
  background-color: var(--danger);
  color: white;
}
