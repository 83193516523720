.it-list {
  display: flex;
  flex-direction: column;

  .it-item {
    border: 1px solid rgba(0, 0, 0, 0.18);
    padding: 0.5rem;
    border-radius: 3px;
    border-left: 3px solid var(--primary);
    margin-bottom: 8px;

    .item-title {
      font-size: 1rem;
      margin-bottom: 0.25rem;
      font-weight: 400;
      letter-spacing: unset;
      word-break: break-word;
    }

    .item-subtitle {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
    }

    .item-value {
      font-weight: 500;
      color: var(--primary);
      font-size: 18px;
    }
  }
}