.mat-paginator {

    .mat-paginator-navigation-first,
    .mat-paginator-navigation-previous,
    .mat-paginator-navigation-next,
    .mat-paginator-navigation-last {
        width: 26px;
        height: 26px;
        line-height: 26px;
        background-color: var(--medium);
        margin: 0 0.25rem;

        .mat-paginator-icon {
            width: 20px;
            height: 20px;
            margin-top: -2px;
        }
    }

}

.mat-paginator-page-size-select {
    width: 60px;
    margin: 0 4px !important;
}

.mat-select-arrow-wrapper {
    border-left: 1px solid #ccc;
}

.mat-select-arrow {
    width: 10px !important;
    height: 6px !important;
    border: none !important;
    overflow: hidden !important;
    position: relative;
    margin-top: 1px !important;

    &:after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border: 2px solid;
        transform: rotate(45deg);
        top: -6px;

    }
}

mat-form-field {

    .mat-form-field-flex {
        border: 1px solid #ccc;
        border-radius: 3px;
        padding: 0 0.25rem;
    }
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: transparent;
    height: 0px;
    display: none !important;
}

.mat-form-field-infix {
    border-top: none !important;
}

.mat-paginator-container {
    min-height: 45px !important;
}