* {
  &:focus {
    outline: 0;
  }
}

.h100 {
  height: 100% !important;
}

.w100 {
  width: 100% !important;
}

.vertical-flex,
.v-flex {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.ab {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
}

.rel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 2;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden !important;
}

// search screens CSS
.content-body {
  padding: 1rem;
  height: 100%;

  .header {
    display: flex;
    align-items: center;
  }
  .pt100 {
    padding-top: 100px;
  }
}

.mat-menu-panel {
  &.menu-box {
    border-radius: 0px !important;
    background-color: var(--primary) !important;
    color: #ffffff;
    margin-top: -1px;
    font-weight: 100 !important;
  }
}

.hw10 {
  height: 10px !important;
  width: 10px !important;
  padding-bottom: 1px;
}

h5 {
  &.heading {
    margin: 0 !important;
    color: var(--secondary);
    font-size: 15px !important;
    padding-bottom: 0.5rem;
  }
}

.border {
  border: 1px solid #e8e8e8 !important;
}

.border-right {
  border-right: 1px solid #e8e8e8 !important;
}

.border-left {
  border-left: 1px solid #e8e8e8 !important;
}

.border-top {
  border-top: 1px solid #e8e8e8 !important;
}

.border-bottom {
  border-bottom: 1px solid #e8e8e8 !important;
}

.mat-tab-label {
  height: 40px !important;
  font-weight: 400;
  border-radius: 5px 5px 0 0;
  // border-radius: 15px 15px 0 0;
  margin-right: 3px;
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;

  &[aria-selected="true"] {
    // background-color: #0371be;
    background-color: var(--primary);
    opacity: 1 !important;
    border-radius: 5px 5px 0 0;
    // border-radius: 15px 15px 0 0;
    color: #ffffff !important;
  }
}

.mat-tab-header {
  height: 40px;
  line-height: 40px;
  border-bottom: 0 !important;
}

.mat-ink-bar {
  visibility: hidden !important;
}

.mat-tab-body-wrapper,
.mat-tab-group {
  height: 100% !important;
}

.mat-tab-body {
  border: 1px solid #e0e0e0;
}
.mat-tab-group {
  &.vertical {
    display: flex !important;
    flex-direction: row !important;
    .mat-tab-body-wrapper {
      width: 100%;
    }
    .mat-tab-labels,
    .mat-tab-label {
      display: block !important;
    }
    .mat-tab-header {
      height: auto !important;
    }
    .mat-tab-label {
      border-radius: 0px 5px 5px 0 !important;
      height: 40px !important;
      font-weight: 400;
      margin-bottom: 3px;
      border-top: 1px solid #cccccc;
      border-left: 1px solid #cccccc;
      border-right: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
    }
  }
}
.icon-pending {
  color: #ffa500;
}

.icon-active {
  color: #048804;
}

.icon-inactive {
  color: #e00202;
}

.form-group {
  .label-value {
    font-size: 11.5px !important;
  }

  b {
    font-weight: 500;
    font-size: 13.5px;
    line-height: 21px;
  }
}

.h50px {
  height: 50px;
}

.h45px {
  height: 45px;
}

.w45px {
  width: 45px;
}

.main-name {
  font-weight: 500;
  font-size: 16px;
  color: var(--primary);
}

.main-subname {
  color: #a5a5a5;
  font-weight: 100;
  font-size: 13px;
}

.mh200 {
  max-height: 200px;
  min-height: 150px;
}

.box-dashboard {
  .box-1,
  .box-2,
  .box-3 {
    color: #ffffff;
    text-align: center;
    padding: 0.8rem;
    font-weight: 400;
    border-radius: 5px;
    margin-right: 1rem;
    min-width: 190px;
    cursor: pointer;

    span {
      font-weight: 500;
      font-size: 25px;
    }
  }

  .box-1 {
    background-color: #ff7171;
  }

  .box-2 {
    background-color: #24ac9a;
  }

  .box-3 {
    background-color: #ffb03a;
  }
}

.img-profile {
  span {
    font-size: 15px;
    font-weight: 400;
    color: var(--secondary) !important;
  }

  ion-icon {
    color: var(--secondary);
    font-size: 16px;
  }

  .img-upload {
    margin: 0 !important;
    position: relative;
    min-height: 180px;
    max-height: 180px;

    label {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      position: absolute;
      z-index: 1;
      border: 1px solid #b2b2b2;
      border-radius: 3px;
      background-color: #ffffff;
      cursor: pointer;
    }

    .placeholder {
      margin: auto;
      z-index: 2;
      font-weight: 100;
      color: #e2e2e2;
    }

    .z-2 {
      z-index: 2 !important;
    }

    input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      width:0;
      height: 0;
    }
    img{
      width:100%;
    }
  }
}
.link {
  font-size: 13.5px;
  color: var(--secondary);
  text-decoration: underline;
  cursor: pointer;
  padding-top: 5px;
}

.blank-logo {
  height: 45px;
  width: 45px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #cccaca;
  font-size: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.mat-button-toggle-checked {
  background-color: var(--secondary) !important;
  width: 100px;
  color: #ffffff;
}
.mat-button-toggle {
  width: 100px !important;
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #ffffff;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
}
.nomar {
  .form-group {
    margin: 0 !important;
  }
}
.w300 {
  width: 300px;
}
.label-space {
  height: 19px;
}
.w-auto {
  width: auto !important;
}
.mh230 {
  height: 230px;
}
.text-left {
  text-align: left;
}
.edit-link {
  color: var(--secondary);
  font-size: 12px;
  cursor: pointer;
}
.form-2 {
  min-height: 100px !important;
  max-height: 100px !important;
}
.text-active {
  color: #059405 !important;
}
.text-pending {
  color: #ea8f07 !important;
}
.text-inactive {
  color: #e00202 !important;
}
.address-box {
  background-color: #e7e7e7a6;
}
.no-panel-pad {
  .pt68 {
    padding-top: 0 !important;
  }
}
.pt68 {
  padding-top: 48px;
}
.message-portal {
  width: 100% !important;

  app-message-portal {
    width: 100% !important;
  }
}
.message-box-expanded {
  display: block !important;
  min-width: 100% !important;
}
.mat-simple-snackbar-action {
  span {
    color: rgb(216, 216, 216) !important;
  }
}
.successimg {
  width: 200px;
  height: 200px;
}
.w250 {
  width: 250px;
}
.mat-datepicker-content .mat-calendar {
  width: 270px !important;
  height: 350px !important;
}
.mw300 {
  min-width: 300px;
}
//email
.email-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .tomail {
    font-size: 16.5px;
    font-weight: 500;
    color: var(--primary);
  }

  .sub {
    font-weight: 500;
  }

  .mail-msg {
    white-space: pre-wrap;
    max-width: 500px;
    line-height: 20px;
    word-break: break-word;
    overflow: auto;
  }
}
.badge-success {
  color: #fff !important;
  background-color: #009d92 !important;
  font-weight: 300 !important;
  padding: 6px 10px !important;
}
.badge-danger {
  font-weight: 300 !important;
  padding: 6px 10px !important;
}
.mat-snack-bar-container{
  margin: 24px 0 0 0 !important;
}

.strength-dropdown {
  background-color: white;
  box-shadow: -2px 3px 15px 0 rgb(0 0 0 / 12%);
  border-radius: 4px;

  .password-cover {
    width: 100%;
    padding: 0.5rem;

    h4 {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .mat-card {
    box-shadow: none;

    .mat-card-content {
      .info-row {
        margin-bottom: 0.25rem;
      }
    }
  }
}