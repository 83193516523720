.mat-dialog-container {
  padding: 0 !important;
}

.dialog-box {
  display: flex;
  flex-direction: column;
  height: 100%;

  .dialog-header {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 1rem 0 2rem;
    border-bottom: 1px solid #e8e8e8;

    h6 {
      font-weight: 400;
      font-size: 16px;
      color: var(--primary);
      margin: 0 !important;
    }
  }

  .mat-dialog-content {
    padding: 1rem;
    margin: 0 !important;
    flex: 1;
    position: relative;
    height: 100%;
    max-height: 100% !important;
    width: 100% !important;
  }

  .mat-dialog-actions {
    margin: 0 !important;
    max-height: 50px;
    min-height: 50px;
    border-top: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }
}

.mat-icon-button {
  font-size: 18px;
  line-height: 40px;
}

.mat-dialog-actions {
  margin: 0 !important;
}
.mat-dialog-content {
  padding: 1rem;
  margin: 0 !important;
}
