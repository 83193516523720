.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.flex-1 {
  flex: 1;
}

.ab {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
}

.rel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 2;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.f18 {
  font-size: 18px;
}

.f12 {
  font-size: 12px;
}

.f14 {
  font-size: 14px;
}

.f16 {
  font-size: 16px;
}

.f21 {
  font-size: 21px;
}

.f24 {
  font-size: 24px;
}

.f25 {
  font-size: 25px;
}

.f26 {
  font-size: 26px;
}

.f28 {
  font-size: 28px;
}

.f36 {
  font-size: 36px;
}
