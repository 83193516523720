.table-responsive {
  position: relative;
  height: 100%;
  // border-top: 1px solid rgb(178 178 178);

  &.with-pad {
    .mat-header-cell,
    .mat-cell {
      padding: 0.5rem !important;
    }
  }
  .mat-header-row,
  .mat-row {
    height: 38px !important;
    border-bottom: 1px solid #ececec !important;
    &:nth-child(even) {
      // background-color: red;
    }
  }

  table {
    width: 100%;
  }
  .mat-row:hover {
    background-color: #ebebeb !important;
  }
  .active {
    background-color: #e0f0fc !important;
  }

  .mat-header-cell {
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    background-color: #a5c9e4;
    padding: 0.5rem !important;
    color: var(--dark);
    // border-bottom: 1px solid rgb(178 178 178);
    // border-right: 1px solid rgb(178 178 178);
    &:first-child {
      // border-right: 1px solid rgb(178 178 178);
      // border-left: 1px solid rgb(178 178 178);
    }
    &:last-child {
      // border-right: 1px solid rgb(178 178 178);
    }
  }

  .mat-cell {
    font-size: 14px;
    font-weight: 100;
    line-height: 19px;
    color: rgba(97, 97, 97);
    padding: 0.5rem;
    // border-bottom: 1px solid rgb(178 178 178);
    // border-right: 1px solid rgb(178 178 178);
    &:first-child {
      // border-right: 1px solid rgb(178 178 178);
      // border-left: 1px solid rgb(178 178 178);
    }
    &:last-child {
      // border-right: 1px solid rgb(178 178 178);
      // border-right: 0px !important
      border-bottom: 1px solid #ececec !important;
    }
  }

  .mat-icon-button {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .table-link {
    cursor: pointer;
    color: var(--blue) !important;
  }

  thead th,
  thead td {
    position: sticky;
    white-space: nowrap;
    z-index: 2;
    top: 0;
    background-color: white;
  }

  .table-bordered {
    .mat-header-cell,
    .mat-cell {
      border: 1px solid rgb(178 178 178);
    }
  }

  .sticky-table {
    border-collapse: separate;
    border-spacing: 0;

    .mat-header-cell,
    .mat-cell {
      border-right: 0px solid transparent;
      z-index: 1;
    }

    .mat-cell {
      border-top: 0px solid transparent;
    }

    .sticky {
      position: sticky;
      background-color: white;
      z-index: 3;
    }
  }
}

.sticky {
  tr td {
    position: sticky;
    bottom: 0;
    background-color: white;
  }
}

thead {
  .tr-56 {
    th {
      top: 56px;
    }
  }
}

tfoot tr th {
  font-weight: 600 !important;
  z-index: 4;
  position: sticky;
  bottom: 0;
  background-color: white !important;
  border-left: 1px solid #d8d8d8 !important;
  border-bottom: 1px solid #d8d8d8 !important;
  border-top: 1px solid #d8d8d8 !important;
}
th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding: 0.5rem !important;
}
th {
  padding: 0.5rem !important;
}
th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding: 0.5rem !important;
}

.text-approved {
  background-color: #048804;
  color: #ffffffe0 !important;
}
.text-rejected {
  background-color: #bb1407;
  color: #ffffffe0 !important;
}
.text-open {
  background-color: var(--secondary);
  color: #ffffffe0 !important;
}

// circle or square icon
.icon-approved {
  color: #048804 !important;
  padding-right: 8px;
}
.icon-rejected {
  color: #bb1407 !important;
  padding-right: 8px;
}
.icon-open {
  color: var(--secondary) !important;
  padding-right: 8px;
}
th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding: 0 0.5rem !important;
}
