.login {
  .header {
    margin: 20px 34px 0 34px;
    img {
      height: 50px;
    }
  }
  .info {
    color: var(--secondary);
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;
    padding-left: 15%;
  }
  .content-panel {
    padding-left: 90px;
    padding-top: 34px;
    height: 300px;
    width: 100%;
    background-color: var(--primary);
    color: #ffffff;
    font-size: 15px;
    font-weight: 100;
    h5 {
      font-size: 34px;
      font-weight: 300;
    }
    .pad {
      padding-bottom: 5px;
      color: #ffffffcc;
    }
  }
  .footer {
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 16px;
    color: var(--primary);
  }
  .login-box {
    // right: 30%;
    // position: absolute;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // left: 70%;
    // bottom: 50%;

    z-index: 1;
    height: 480px;
    width: 360px;
    background-color: #ffffff;
    border-top: 3px solid var(--secondary);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 7%), 0 6px 20px 0 rgb(0 0 0 / 6%);
    border-radius: none;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    .login-head {
      font-size: 24px;
      color: var(--secondary);
      padding-bottom: 29px;
    }
    .login-btn {
      background-color: var(--secondary);
      color: #ffffff;
      border-radius: 20px;
      height: 37px;
      font-size: 15px;
    }
    .btn-forgot {
      // box-shadow: none !important;
      width: 100%;
      height: 36px;
      overflow: hidden;
      // color: var(--secondary);
      // text-decoration: underline;
      .icon-btn2 {
        width: 36px;
        height: 36px;
        background-color: var(--primary);
        margin-right: -1rem;
        font-size: 18px;
        color: white;
        text-align: center;
        line-height: 40px;
      }
    }
    .w140 {
      width: 140px;
    }
    .help {
      padding-top: 37px;
      font-size: 14px;
      a {
        color: var(--secondary);
        text-decoration: underline;
        padding-left: 5px;
      }
    }
  }
  .form-control {
    height: 36px;
    input {
      border: 1px solid #dee1e6;
    }
  }
  .mh500 {
    min-height: 500px;
  }
  .info-msg {
    color: var(--primary);
    font-size: 11px;
  }
  .mr122 {
    margin-right: 10%;
  }
}
