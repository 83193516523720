.search-panel {
  width: 100%;
  min-width: 700px;
  min-height: 250px;
  transition: all 1s ease;
  background-color: white;
  box-shadow: rgb(0 0 0 / 24%) -5px 6px 35px 5px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  margin-left: 1rem;
  // border-top: 2px solid var(--primary);

  hr {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .search-footer {
    height: 50px;
    position: relative;
    // position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: white;
  }
}