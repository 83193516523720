.mega-menu {
    // width: 100%;
    min-height: 427px;
    position: relative;

    .dialog-cover {
        height: 100%;
        // background-color: var(--primary);
        background-color: var(--primary);
        width: 100%;
        max-width: 1054px;
        box-shadow: 0 10px 25px 5px rgb(0 0 0 / 24%);
        margin: auto;
        left: 50%;
        position: fixed;
        transform: translateX(-50%);
        max-height: 427px;
    }

    .app-mega-menu {
        width: 100%;
    }

    // 
}

.mega-menu-backdrop {
    background-color: transparent;
    top: 50px;
}

.menu-panel {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;

    .menu-box {
        flex: 1;
        color: white;

        .menu-header {
            height: 40px;
            border-bottom: 1px solid rgba(178, 178, 178, 0.5);
            overflow: hidden;
            padding: 0.7rem 1rem 0 1.5rem;
            font-size: 14px;
            font-weight: 500;
        }

        .menu-list {
            list-style: none;
            padding: 2px 0.5rem 0 0.5rem;
            margin: 0;

            .menu-item {
                cursor: pointer;
                padding: 0.4rem 1rem;
                font-size: 13px;
                font-weight: 400;
                min-height: 32px;

                &:hover {
                    background-color: var(--secondary);
                }
            }
        }
    }

}